import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

export function INTERNAL__useGetAttributionsByMonth({
	userId,
	initialData,
}: {
	userId: number;
	initialData?: schemas['AttributionsByMonth'];
}) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.affiliates.attributionsByMonth({ userId }),
		initialData,
	});
}
