import type { paths } from '@features/api/schema.d';
import createClient from 'openapi-fetch';

import { INTERNAL__affiliatesApiClient } from '@features/affiliates/api/_affiliatesApiClient';
import { INTERNAL__bankTransactionsApiClient } from '@features/backoffice/accounting/transactions/api/_bankTransactionsApiClient';
import { INTERNAL__digitalTextbooksApiClient } from '@features/backoffice/digital-textbooks/api/_digitalTextbooksApiClient';
import { INTERNAL__problemsImportApiClient } from '@features/backoffice/problems-import/api/_problemsImportApiClient';
import { INTERNAL__quizzesApiClient } from '@features/frontoffice/quiz/data/_quizzesApi.client';
import { INTERNAL__admissionScoreCalculatorsClientApi } from '@features/webshop/admission-score-calculators/api/_admissionScoreCalculatorsClientApi';
import { INTERNAL__cartApiClient } from '@features/webshop/cart/api/cartApiClient';
import { INTERNAL__revenueApiClient } from '@features/backoffice/accounting/revenue/api/_revenueApiClient';
import { INTERNAL__coursesApiClient } from '@features/frontoffice/courses/data/_coursesApiClient';

export const apiClientFetch = createClient<paths>({
	baseUrl: process.env.NEXT_PUBLIC_API_BASE_PATH,
	fetch(input) {
		const headers = new Headers(input.headers);

		const authHeaders = input.headers.get('Authorization');

		if (authHeaders) {
			headers.set('Authorization', authHeaders);
		}

		return fetch(input, { headers });
	},
	querySerializer: {
		array: {
			style: 'form',
			explode: false,
		},
	},
});

export type ApiClientFetch = typeof apiClientFetch;

export const apiClient = {
	courses: INTERNAL__coursesApiClient,
	quizzes: INTERNAL__quizzesApiClient,
	problemsImport: INTERNAL__problemsImportApiClient,
	bankTransactions: INTERNAL__bankTransactionsApiClient,
	digitalTextbooks: INTERNAL__digitalTextbooksApiClient,
	cart: INTERNAL__cartApiClient,
	affiliates: INTERNAL__affiliatesApiClient,
	admissionScoreCalculators: INTERNAL__admissionScoreCalculatorsClientApi,
	revenue: INTERNAL__revenueApiClient,
};
