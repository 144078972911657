import { apiClientFetch } from '@features/api/api.client';
import type { schemas } from '@features/api/schema';
import type { operations } from '@features/api/schema.d';
import useApiClient from '@features/api/useApiClient';
import { useInfiniteQuery } from '@tanstack/react-query';

export function INTERNAL__useGetBankTransactionList({
	requestData,
	initialData,
}: {
	requestData: operations['getBankAccountTransactionList']['parameters']['query'];
	initialData?: {
		pages: Array<schemas['PaginatedBankAccountTransactionList']>;
		pageParams: Array<number>;
	};
}) {
	const { queries } = useApiClient();

	return useInfiniteQuery({
		queryKey: queries.bankTransactions.transactions._ctx.list(requestData).queryKey,
		queryFn: () => {
			return apiClientFetch
				.GET('/wt/backoffice/api/bank-transaction/', {
					params: {
						query: requestData,
					},
				})
				.then((res) => res.data);
		},
		initialPageParam: 1,
		initialData: initialData,
		getNextPageParam: (lastPage) => {
			if (!lastPage?.next || !requestData.offset) return undefined;

			return requestData.offset + 1;
		},
		select: (data) => {
			const lastPage = data.pages.at(-1);

			return {
				results: lastPage?.results,
				pages: data.pages,
				count: lastPage?.count || 0,
				pageParams: data.pages.map((page, index) => index + 1),
			};
		},
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});
}
