import useApiClient from '@features/api/useApiClient';
import formatDateForRequest from '@features/shared/utils/formatDateForRequest';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

export function INTERNAL__useGetBalance({ fromDate, toDate }: { fromDate: dayjs.Dayjs; toDate: dayjs.Dayjs }) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.revenue.balance({
			from_date: formatDateForRequest(fromDate),
			to_date: formatDateForRequest(toDate),
		}),
	});
}
