import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';
import type { operations } from '@features/api/schema.d';

export type GetStudyProgramsListRequestData =
	operations['webshop_api_university_study_programs_list']['parameters']['query'];

export function INTENRAL__useGetStudyProgramsList({
	universityId,
	enabled,
}: {
	universityId: number;
	enabled: boolean;
}) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.admissionScoreCalculators.studyPrograms._ctx.list({
			university_institution: universityId,
		}),
		enabled,
	});
}
