import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { schemas } from '@features/api/schema';

export function INTERNAL__useCreateBankTransactionCategoryAttribution() {
	const queryClient = useQueryClient();
	const { apiFetch, queries } = useApiClient();

	return useMutation({
		mutationFn: (body: schemas['BankAccountTransactionCategoryAttributionRequest']) => {
			return apiFetch.POST('/wt/backoffice/api/bank-transaction-category-attribution/', {
				body,
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.bankTransactions.transactions.queryKey,
			});
		},
	});
}
