import useApiClient from '@features/api/useApiClient';
import { skipToken, useQuery } from '@tanstack/react-query';

export function INTERNAL__useGetCourseCategory({ courseId }: { courseId?: number }) {
	const { queries } = useApiClient();
	return useQuery({
		queryKey: courseId
			? queries.courses.course(courseId)._ctx.courseCategory.queryKey
			: (skipToken as any),
		queryFn: courseId ? queries.courses.course(courseId)._ctx.courseCategory.queryFn : skipToken,
		enabled: !!courseId,
	});
}
