/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `1` - Kategorija 1 * `2` - Kategorija 2 * `3` - Kategorija 3 * `4` - Kategorija 4 * `5` - Kategorija 5 * `6` - Kategorija 6
 * @export
 * @enum {string}
 */

export const CategoryToEnum = {
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6'
} as const;

export type CategoryToEnum = typeof CategoryToEnum[keyof typeof CategoryToEnum];



