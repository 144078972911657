import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { schemas } from '@features/api/schema';
export function INTERNAL__useCreateBankTransactionCategory({ transactionId }: { transactionId?: number }) {
	const { queries, apiFetch } = useApiClient();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (body: schemas['BankAccountTransactionCategoryRequest']) => {
			const { data, error } = await apiFetch.POST('/wt/backoffice/api/bank-transaction-category/', {
				body,
			});

			if (error) throw error;

			return data;
		},
		onSettled: () => {
			if (!transactionId) return;

			queryClient.invalidateQueries({
				queryKey: queries.bankTransactions.transactions._ctx.single({ id: transactionId }).queryKey,
			});
		},
	});
}
