/* eslint-disable no-unused-vars */
import type { QuestionAnswerBlock, QuestionBlock } from '@features/api/client';
import { type ReactNode } from 'react';
import MultipleSizeImageBlock from './MultipleSizeImageBlock';
import { richtext } from '@features/shared/utils/richtext';
import TableBlock from './TableBlock';
import MultipleThemesImageBlock from './MultipleThemesImageBlock';
import { cn } from '@features/shared/utils/utils';

type QuestionContentBlock = QuestionBlock | QuestionAnswerBlock;
type QuestionContentBlockType = QuestionBlock['type'] | QuestionAnswerBlock['type'];
type ContentBlockMap = {
	[K in QuestionContentBlockType]: (block: Extract<QuestionContentBlock, { type: K }>) => ReactNode;
};

const contentBlockMap = {
	dual_size_image: (block) => {
		const { width, height } = block.value.image;
		const aspectRatio = width / height;

		return (
			<MultipleSizeImageBlock
				block={block.value}
				className={cn(
					'workbook__problem-image',
					aspectRatio >= 1 ? 'workbook__problem-image--wide' : 'workbook__problem-image--narrow',
					aspectRatio >= 4 && 'workbook__problem-image--extra-wide max-w-full',
					aspectRatio <= 0.5 && 'workbook__problem-image--extra-narrow max-w-[100px]',
					aspectRatio > 0.5 && aspectRatio < 4 && 'max-w-[600px]'
				)}
			/>
		);
	},
	dual_theme_image: (block) => {
		const { width, height } = block.value.image_light;
		const aspectRatio = width / height;

		return (
			<MultipleThemesImageBlock
				block={block.value}
				className={cn(
					'workbook__problem-image',
					aspectRatio >= 1 ? 'workbook__problem-image--wide' : 'workbook__problem-image--narrow',
					aspectRatio >= 4 && 'workbook__problem-image--extra-wide max-w-full',
					aspectRatio <= 0.5 && 'workbook__problem-image--extra-narrow max-w-[100px]',
					aspectRatio > 0.5 && aspectRatio < 4 && 'max-w-[600px]'
				)}
			/>
		);
	},
	rich_text: (block) => richtext(block.value, 'prose', 'span'),
	table: (block) => <TableBlock block={block.value} />,
	highlight_box: (block) =>
		richtext(block.value, 'prose p-3 rounded-[3px] bg-green-100 workbook__problem-highlight-box', 'div'),
} satisfies ContentBlockMap;

function ProblemContentBlock<T extends QuestionContentBlock>({ block }: { block: T }) {
	const renderBlock = contentBlockMap[block.type] as (block: T) => ReactNode;

	if (!renderBlock) return null;

	return renderBlock(block);
}

export default ProblemContentBlock;
