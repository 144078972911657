import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function INTERNAL__useCreateBankTransactionCategoryProjectionGroup() {
	const { apiFetch, queries } = useApiClient();

	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (body: schemas['BankAccountTransactionCategoryProjectionGroupCreateRequest']) => {
			const { data, error } = await apiFetch.POST(
				'/wt/backoffice/api/bank-transaction-category-projection-group/',
				{ body }
			);

			if (error) throw error;

			return data;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: queries.revenue.balance({}).queryKey });
			queryClient.invalidateQueries({ queryKey: queries.revenue.revenue({}).queryKey });
			queryClient.invalidateQueries({ queryKey: queries.revenue.projectionGroups.queryKey });
		},
	});
}
