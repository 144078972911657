import { INTERNAL__useGetBalance } from '@features/backoffice/accounting/revenue/api/client/_useGetBalance';
import { INTERNAL__useGetCustomerAcquisitionCost } from '@features/backoffice/accounting/revenue/api/client/_useGetCustomerAcquisitionCost';
import { INTERNAL__useGetRevenue } from '@features/backoffice/accounting/revenue/api/client/_useGetRevenue';
import { INTERNAL__useGetCustomerLifetimeValue } from '@features/backoffice/accounting/revenue/api/client/_useGetCustomerLifetimeValue';
import { INTERNAL__useGetCategoryProjectionGroupList } from '@features/backoffice/accounting/revenue/api/client/_useGetCategoryProjectionGroupList';
import { INTERNAL__useCreateBankTransactionCategoryProjectionGroup } from '@features/backoffice/accounting/revenue/api/client/_useCreateBankTransactionCategoryProjectionGroup';
import { INTERNAL__useUpdateBankTransactionCategoryProjection } from '@features/backoffice/accounting/revenue/api/client/_useUpdateBankTransactionCategoryProjection';
import { INTERNAL__useUpdateBankTransactionCategoryProjectionGroup } from '@features/backoffice/accounting/revenue/api/client/_useUpdateBankTransactionCategoryProjectionGroup';
export const INTERNAL__revenueApiClient = {
	useGetRevenue: INTERNAL__useGetRevenue,
	useGetBalance: INTERNAL__useGetBalance,
	useGetCustomerAcquisitionCost: INTERNAL__useGetCustomerAcquisitionCost,
	useGetCategoryProjectionGroupList: INTERNAL__useGetCategoryProjectionGroupList,
	useCreateBankTransactionCategoryProjectionGroup:
		INTERNAL__useCreateBankTransactionCategoryProjectionGroup,
	useGetCustomerLifetimeValue: INTERNAL__useGetCustomerLifetimeValue,
	useUpdateBankTransactionCategoryProjection: INTERNAL__useUpdateBankTransactionCategoryProjection,
	useUpdateBankTransactionCategoryProjectionGroup:
		INTERNAL__useUpdateBankTransactionCategoryProjectionGroup,
};
