import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

export function INTERNAL__useGetUploadedFilesFromGoogleDrive({ transactionId }: { transactionId: number }) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.bankTransactions.transactions._ctx.uploadedFiles({ id: transactionId! }),
	});
}
