import type { ApiClientFetch } from '@features/api/api.client';
import type { operations } from '@features/api/schema.d';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const INTERNAL__revenueQueryConfig = (apiFetch: ApiClientFetch) =>
	createQueryKeys('revenue', {
		customerAcquisitionCost: (body: operations['getCustomerAcquisitionCost']['parameters']['query']) => ({
			queryKey: ['customer-acquisition-cost', ...Object.values(body || {})],
			queryFn: async () => {
				const { data, error } = await apiFetch.GET(
					'/wt/backoffice/api/customer-acquisition-cost/customer_acquisition_cost/',
					{
						params: {
							query: body,
						},
					}
				);

				if (error) throw error;

				return data;
			},
		}),
		customerLifetimeValue: (body: operations['getCustomerLifetimeValue']['parameters']['query']) => ({
			queryKey: ['customer-lifetime-value', ...Object.values(body || {})],
			queryFn: async () => {
				const { data, error } = await apiFetch.GET(
					'/wt/backoffice/api/customer-lifetime-value/customer_lifetime_value/',
					{
						params: {
							query: body,
						},
					}
				);

				if (error) throw error;

				return data;
			},
		}),
		revenue: (body?: operations['getRevenue']['parameters']['query']) => ({
			queryKey: ['revenue', ...Object.values(body || {})],
			queryFn: async () => {
				const { data, error } = await apiFetch.GET('/wt/backoffice/api/revenue/revenue/', {
					params: {
						query: body,
					},
				});

				if (error) throw error;

				return data;
			},
		}),
		balance: (body: operations['getBalance']['parameters']['query']) => ({
			queryKey: ['balance', ...Object.values(body || {})],
			queryFn: async () => {
				const { data, error } = await apiFetch.GET('/wt/backoffice/api/balance/balance/', {
					params: {
						query: body,
					},
				});
				if (error) throw error;

				return data;
			},
		}),
		projectionGroups: {
			queryKey: ['projectionGroups'],
			contextQueries: {
				list: (
					body: operations['get_bank_account_transaction_category_projection_group_list']['parameters']['query']
				) => ({
					queryKey: ['list', ...Object.values(body || {})],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/backoffice/api/bank-transaction-category-projection-group/',
							{
								params: {
									query: body,
								},
							}
						);
						if (error) throw error;

						return data;
					},
				}),
				single: (id: number) => ({
					queryKey: ['single', id],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							`/wt/backoffice/api/bank-transaction-category-projection-group/{id}/`,
							{
								params: {
									path: {
										id,
									},
								},
							}
						);
						if (error) throw error;

						return data;
					},
				}),
			},
		},
	});
