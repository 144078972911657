'use client';

import type { QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import { IconCheck, IconX } from '@tabler/icons-react';
import { cn } from '@features/shared/utils/utils';
import { Radio, Space } from 'antd';
import { useQuestionGroupStore } from '../Question/QuestionGroupStore';
import QuestionAnswer from './QuestionAnswer';
import { QuestionsViewMode } from '@features/frontoffice/problems/ui/Question/QuestionsViewMode.types';

type QuestionAnswerChoiceProps = {
	answer: QuestionAnswerType;
	answerIndex: number;
	questionIndex: number;
};

function QuestionAnswerChoice({ answer, answerIndex, questionIndex }: QuestionAnswerChoiceProps) {
	const areAnswersBeingChecked = useQuestionGroupStore((state) => state.areAnswersBeingChecked);
	const setAreAnswersBeingChecked = useQuestionGroupStore((state) => state.setAreAnswersBeingChecked);
	const selectedAnswerChoices = useQuestionGroupStore((state) => state.selectedAnswerChoices);
	const isChangingAnswersDisabled = useQuestionGroupStore((state) => state.isChangingAnswersDisabled);
	const mode = useQuestionGroupStore((state) => state.mode);

	const isSelected = selectedAnswerChoices.some((selectedAnswer) => selectedAnswer.answerId === answer.id);

	return (
		<div
			className={cn(
				'prose w-full',
				areAnswersBeingChecked &&
					answer.is_correct &&
					'text-green-600 [&_.ant-radio-wrapper_.ant-radio-checked_.ant-radio-inner]:border-green-600 [&_.ant-radio-wrapper_.ant-radio-checked_.ant-radio-inner]:bg-green-600',
				areAnswersBeingChecked &&
					!answer.is_correct &&
					'text-red-600 [&_.ant-radio-wrapper_.ant-radio-checked_.ant-radio-inner]:border-red-600 [&_.ant-radio-wrapper_.ant-radio-checked_.ant-radio-inner]:bg-red-600'
			)}>
			<Radio
				className="w-full"
				value={answer.id}
				onClick={() => {
					if (isChangingAnswersDisabled || mode === QuestionsViewMode.IMPORT_REVIEW) return;

					setAreAnswersBeingChecked(false);
				}}
				disabled={!isSelected && isChangingAnswersDisabled}>
				<Space size="small" className="w-full">
					<QuestionAnswer answer={answer} answerIndex={answerIndex} questionIndex={questionIndex} />
					{areAnswersBeingChecked && !answer.is_correct && isSelected && (
						<IconX className="text-red-600" />
					)}
					{areAnswersBeingChecked && answer.is_correct && isSelected && (
						<IconCheck className="text-green-600" />
					)}
				</Space>
			</Radio>
		</div>
	);
}

export default QuestionAnswerChoice;
