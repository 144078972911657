import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { getRandomInt } from '@features/shared/utils/getRandomInt';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { QueryKeyToReturnType } from 'settings/types';

export function INTERNAL__useUpdateBankTransaction({
	accountId,
	tableParams,
}: {
	accountId: number;
	tableParams?: {
		limit: number;
		offset: number;
	};
}) {
	const { queries, apiFetch } = useApiClient();
	const queryClient = useQueryClient();

	const listQueryKey = queries.bankTransactions.transactions._ctx.list({
		account: accountId,
		...tableParams,
	}).queryKey;

	type VendorsResultType = QueryKeyToReturnType<
		typeof queries.bankTransactions.transactions._ctx.vendors._ctx.list
	>;

	type CategoriesResultType = QueryKeyToReturnType<
		typeof queries.bankTransactions.transactions._ctx.categories._ctx.list
	>;

	return useMutation({
		mutationFn: async ({
			id,
			body,
		}: {
			id: number;
			body: schemas['PatchedBankAccountTransactionUpdateRequest'];
		}) => {
			const { data, error } = await apiFetch.PATCH('/wt/backoffice/api/bank-transaction/{id}/', {
				body,
				params: {
					path: {
						id,
					},
				},
			});

			if (error) throw error;

			return data;
		},
		onMutate: async (requestData) => {
			queryClient.cancelQueries({
				queryKey: queries.bankTransactions.transactions.queryKey,
			});

			const previousData = queryClient.getQueryData(listQueryKey);

			const vendors = queryClient.getQueryData<VendorsResultType>(
				queries.bankTransactions.transactions._ctx.vendors._ctx.list({}).queryKey
			);

			const categories = queryClient.getQueryData<CategoriesResultType>(
				queries.bankTransactions.transactions._ctx.categories._ctx.list({}).queryKey
			);

			queryClient.setQueryData(listQueryKey, (old: any) => {
				if (!old) return;

				const pages: schemas['PaginatedBankAccountTransactionList'][] = old?.pages;

				const newPages = pages.map((page, index) => {
					if (index !== tableParams?.offset) return page;

					const vendor = vendors?.results.find((vendor) => vendor.id === requestData?.body.vendor);

					const category = categories?.results.find(
						(category) => category.id === requestData.body.category
					);

					return {
						...page,
						results: page.results.map((transaction) => {
							if (transaction.id !== requestData.id) return transaction;

							return {
								...transaction,
								vendor: requestData?.body.vendor
									? {
											id: getRandomInt(100000),
											name: vendor?.name,
										}
									: transaction.vendor,
								category: requestData?.body.category
									? {
											id: getRandomInt(100000),
											name: category?.name,
										}
									: transaction.category,
							};
						}),
					};
				});

				return {
					...old,
					pages: newPages,
				};
			});

			return { previousData };
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.bankTransactions.transactions.queryKey,
			});
		},
		onError: (err, requestData, context) => {
			console.error(err);
			queryClient.setQueryData(listQueryKey, context?.previousData);
		},
	});
}
