import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function INTERNAL__useUpdateBankTransactionCategoryProjectionGroup() {
	const { apiFetch, queries } = useApiClient();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({
			id,
			body,
		}: {
			id: number;
			body: schemas['PatchedBankAccountTransactionCategoryProjectionGroupRequest'];
		}) => {
			const { data, error } = await apiFetch.PATCH(
				`/wt/backoffice/api/bank-transaction-category-projection-group/{id}/`,
				{ body, params: { path: { id } } }
			);

			if (error) throw error;

			return data;
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.revenue.projectionGroups._ctx.list({}).queryKey,
			});
		},
	});
}
