import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

export function INTERNAL__useConfirmPayout({ userId }: { userId: number }) {
	const queryClient = useQueryClient();
	const { apiFetch, queries } = useApiClient();
	const [messageApi, contextHolder] = message.useMessage();

	const queryKey = queries.affiliates.attributionsByMonth({ userId }).queryKey;

	const mutation = useMutation({
		mutationFn: async (body: { year_month: string }) => {
			const { data, error } = await apiFetch.POST(
				'/wt/affiliates-portal/api/affiliate-profile/{user_id}/confirm_payout/',
				{
					params: {
						path: {
							user_id: userId,
						},
					},
					body: {
						year_month: body.year_month,
					},
				}
			);

			if (error) throw error;

			return data;
		},
		onMutate: () => {
			queryClient.cancelQueries({
				queryKey,
			});

			const previousData = queryClient.getQueryData(queryKey);

			queryClient.setQueryData(queryKey, (oldData?: schemas['AttributionsByMonth']) => {
				if (!oldData) return oldData;

				return {
					...oldData,
					attributions: oldData.attributions.map((a) => ({
						...a,
						status: 'paid_unconfirmed',
					})),
				};
			});

			return { previousData };
		},
		onSuccess: (responseData) => {
			queryClient.setQueryData(queryKey, responseData);

			messageApi.success('Payout confirmed');
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey,
			});
		},
		onError: (error, variables, context) => {
			console.error(error);
			queryClient.setQueryData(queryKey, context?.previousData);

			messageApi.error('Failed to confirm payout, Please contact admin.');
		},
	});

	return { ...mutation, messagesContextHolder: contextHolder };
}
