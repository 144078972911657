import type { ApiClientFetch } from '@features/api/api.client';
import type { operations } from '@features/api/schema.d';
import constants from '@features/backoffice/shared/domain/constants';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const INTERNAL__bankTransactionsQueryConfig = (apiFetch: ApiClientFetch) =>
	createQueryKeys('accounting', {
		balances: {
			queryKey: ['balances'],
			contextQueries: {
				single: () => ({
					queryKey: ['single', constants.BANK_ACCOUNT_DATA_ID],
					queryFn: async () => {
						const res = await apiFetch.GET('/wt/backoffice/api/bank-account/{id}/', {
							params: {
								path: {
									id: constants.BANK_ACCOUNT_DATA_ID,
								},
							},
						});

						if (res.error || !res.data) throw res.error;
						if (!res.data || !('200' in res.data)) return null;

						return res.data[
							'200'
						] as operations['get_bank_account_data']['responses']['200']['content']['application/json'];
					},
				}),
			},
		},
		transactions: {
			queryKey: ['transactions'],
			contextQueries: {
				list: (body: operations['getBankAccountTransactionList']['parameters']['query']) => ({
					queryKey: ['list', ...Object.values(body)],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET('/wt/backoffice/api/bank-transaction/', {
							params: {
								query: body,
							},
						});

						if (error) throw error;

						return data;
					},
				}),
				single: (body: operations['getBankAccountTransaction']['parameters']['path']) => ({
					queryKey: ['single', ...Object.values(body)],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/backoffice/api/bank-transaction/{id}/',
							{
								params: {
									path: {
										id: body.id,
									},
								},
							}
						);

						if (error) throw error;

						return data;
					},
				}),
				uploadedFiles: (
					body: operations['get_uploaded_files_from_google_drive']['parameters']['path']
				) => ({
					queryKey: ['uploaded-files'],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/backoffice/api/bank-transaction/{id}/uploaded-files/',
							{
								params: {
									path: {
										id: body.id,
									},
								},
							}
						);

						if (error) throw error;

						return data;
					},
				}),
				vendors: {
					queryKey: ['vendors'],
					contextQueries: {
						list: (
							body: operations['get_bank_account_transaction_vendor_list']['parameters']['query']
						) => ({
							queryKey: ['list', ...Object.values(body || {})],
							queryFn: async () => {
								const { data, error } = await apiFetch.GET(
									'/wt/backoffice/api/bank-transaction-vendor/',
									{
										params: {
											query: body,
										},
									}
								);

								if (error) throw error;

								return data;
							},
						}),
						single: (
							body: operations['get_bank_account_transaction_vendor']['parameters']['path']
						) => ({
							queryKey: ['single', ...Object.values(body)],
							queryFn: async () => {
								const { data, error } = await apiFetch.GET(
									'/wt/backoffice/api/bank-transaction-vendor/{id}/',
									{
										params: {
											path: {
												id: body.id,
											},
										},
									}
								);

								if (error) throw error;

								return data;
							},
						}),
					},
				},
				categories: {
					queryKey: ['categories'],
					contextQueries: {
						list: (
							body: operations['get_bank_account_transaction_category_list']['parameters']['query']
						) => ({
							queryKey: ['list', ...Object.values(body || {})],
							queryFn: async () => {
								const { data, error } = await apiFetch.GET(
									'/wt/backoffice/api/bank-transaction-category/',
									{
										params: {
											query: body,
										},
									}
								);

								if (error) throw error;

								return data;
							},
						}),
						single: (
							body: operations['get_bank_account_transaction_category']['parameters']['path']
						) => ({
							queryKey: ['single', ...Object.values(body)],
							queryFn: async () => {
								const { data, error } = await apiFetch.GET(
									'/wt/backoffice/api/bank-transaction-category/{id}/',
									{
										params: {
											path: {
												id: body.id,
											},
										},
									}
								);

								if (error) throw error;

								return data;
							},
						}),
					},
				},
				attributions: {
					queryKey: ['attributions'],
					contextQueries: {
						list: (
							body: operations['get_bank_account_transaction_category_attribution_list']['parameters']['query']
						) => ({
							queryKey: ['list', ...Object.values(body || {})],
							queryFn: async () => {
								const { data, error } = await apiFetch.GET(
									'/wt/backoffice/api/bank-transaction-category-attribution/',
									{
										params: {
											query: body,
										},
									}
								);

								if (error) throw error;

								return data;
							},
						}),
					},
				},
			},
		},
	});
