export default {
	token: {
		fontSize: 16,
		wireframe: false,
		colorPrimary: '#003db8',
		colorInfo: '#003db8',
		colorTextBase: '#000000',
		fontFamily: 'var(--font-marlinsoft)',
	},
	components: {
		Menu: {
			fontSize: 16,
			fontSizeLG: 24,
			padding: 20,
			itemHeight: 54,
			iconSize: 20,
			activeBarBorderWidth: 0,
			itemSelectedBg: 'rgb(255, 255, 255)',
			itemBorderRadius: 4,
			itemSelectedColor: 'rgb(0, 0, 0)',
			borderRadius: 4,
			boxShadowSecondary:
				'      0 1px 2px -2px rgba(0, 0, 0, 0.16),      0 3px 6px 0 rgba(0, 0, 0, 0.12),      0 5px 12px 4px rgba(0, 0, 0, 0.09)    ',
			iconMarginInlineEnd: 16,
		},
		Button: {
			contentFontSize: 18,
			contentFontSizeLG: 20,
			contentFontSizeSM: 15,
			onlyIconSize: 18,
			onlyIconSizeLG: 20,
			onlyIconSizeSM: 16,
			paddingBlock: 16,
			paddingBlockLG: 20,
			paddingInline: 32,
			paddingInlineLG: 80,
			paddingInlineSM: 22,
			controlHeight: 56,
			controlHeightLG: 64,
			controlHeightSM: 38,
			borderRadius: 28,
			borderRadiusSM: 28,
			fontWeight: 550,
			borderRadiusLG: 20,
			primaryShadow: '',
			lineWidth: 2,
			paddingBlockSM: 12,
			defaultBg: 'rgb(255,199,0)',
			defaultBorderColor: 'rgb(255,199,0)',
			defaultColor: 'rgb(0,0,0)',
			defaultHoverBg: 'rgb(241,189,0)',
			defaultHoverBorderColor: 'rgb(241,189,0)',
			defaultHoverColor: 'rgb(0,0,0)',
			defaultGhostBorderColor: 'rgb(39,39,39)',
			defaultGhostColor: 'rgb(0,0,0)',
			groupBorderColor: 'rgb(39,39,39)',
		},
		Card: {
			borderRadiusLG: 14,
		},
		Layout: {
			zeroTriggerHeight: 46,
			zeroTriggerWidth: 46,
			headerBg: 'rgb(255, 255, 255)',
		},
	},
};
