import useApiClient from '@features/api/useApiClient';
import type { schemas } from '@features/api/schema';
import { useQuery } from '@tanstack/react-query';
import { type operations } from '@features/api/schema.d';

export function INTERNAL__useGetBankTransactionCategoryList({
	body,
	initialData,
}: {
	body?: operations['get_bank_account_transaction_category_list']['parameters']['query'];
	initialData?: schemas['PaginatedBankAccountTransactionCategoryList'];
}) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.bankTransactions.transactions._ctx.categories._ctx.list(body),
		initialData,
	});
}
