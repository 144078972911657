/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `volume` - Volume * `weight` - Weight * `length` - Length * `temperature` - Temperature * `time` - Time
 * @export
 * @enum {string}
 */

export const MeasurementUnitSuffixEnum = {
    Volume: 'volume',
    Weight: 'weight',
    Length: 'length',
    Temperature: 'temperature',
    Time: 'time'
} as const;

export type MeasurementUnitSuffixEnum = typeof MeasurementUnitSuffixEnum[keyof typeof MeasurementUnitSuffixEnum];



