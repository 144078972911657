import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

export function INTENRAL__useGetStudyProgram({ id, enabled }: { id: number; enabled: boolean }) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.admissionScoreCalculators.studyPrograms._ctx.single({ id }),
		enabled,
	});
}
