import { type ApiClientFetch } from '@features/api/api.client';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { queryKeys } from 'settings/queryKeys';

export const INTERNAL__affiliatesQueryConfig = (apiFetch: ApiClientFetch) =>
	createQueryKeys('affiliates', {
		earnings: ({ userId }: { userId: number }) => ({
			queryKey: ['earnings', userId],
			queryFn: () => {
				return apiFetch
					.GET('/wt/affiliates-portal/api/affiliate-profile/{user_id}/earnings/', {
						params: {
							path: {
								user_id: userId,
							},
						},
					})
					.then((res) => res.data);
			},
		}),
		attributionsByMonth: ({ userId }: { userId: number }) => ({
			queryKey: ['attributions-by-month', userId],
			queryFn: () =>
				apiFetch
					.GET('/wt/affiliates-portal/api/affiliate-profile/{user_id}/attributions_by_month/', {
						params: {
							path: {
								user_id: userId,
							},
						},
					})
					.then((res) => res.data),
		}),
		affiliateProfile: {
			queryKey: ['affiliate-profile'],
			contextQueries: {
				list: () => ({
					queryKey: ['list'],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/affiliates-portal/api/affiliate-profile/',
							{}
						);

						if (error) throw error;

						return data;
					},
				}),
			},
		},
	});
