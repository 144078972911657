import type { ApiClientFetch } from '@features/api/api.client';
import type { GetStudyProgramsListRequestData } from '@features/webshop/admission-score-calculators/api/client/_useGetStudyProgramsList';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const ITENRAL__admissionScoreCalculatorsQueryConfig = (apiFetch: ApiClientFetch) =>
	createQueryKeys('admission-score-calculators', {
		studyPrograms: {
			queryKey: ['study-programs'],
			contextQueries: {
				list: (requestData: GetStudyProgramsListRequestData) => ({
					queryKey: ['list', ...Object.values(requestData || {})],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/webshop/api/university-study-programs/',
							{
								params: {
									query: {
										city_name: requestData?.city_name,
										university_institution: requestData?.university_institution,
										limit: requestData?.limit || 10000,
										offset: requestData?.offset || 0,
									},
								},
							}
						);

						if (error) {
							throw error;
						}

						return data;
					},
				}),
				single: ({ id }: { id: number }) => ({
					queryKey: [id],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/webshop/api/university-study-programs-retrieve/{id}/',
							{
								params: {
									path: {
										id,
									},
								},
							}
						);

						if (error) {
							throw error;
						}

						return data;
					},
				}),
			},
		},
		universities: {
			queryKey: ['universities'],
			contextQueries: {
				list: ({}) => ({
					queryKey: ['list'],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET('/wt/webshop/api/universities/', {
							params: {
								query: {
									limit: 10000,
									offset: 0,
								},
							},
						});

						if (error) {
							throw error;
						}

						return data;
					},
				}),
			},
		},
	});
