'use client';

import { FieldType938Enum, type QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import { useQuestionGroupStore } from '../Question/QuestionGroupStore';
import QuestionAnswer from './QuestionAnswer';
import dynamic from 'next/dynamic';
import { useDebounceCallback } from 'usehooks-ts';
import QuestionAnswerInput from '../Question/QuestionAnswerInput';

const MathKeyboardInput = dynamic(() => import('./MathKeyboardInput'), { ssr: false });

type QuestionAnswerTextProps = {
	answer: QuestionAnswerType;
	questionIndex: number;
	answerIndex: number;
};

function QuestionAnswerText({ answer, questionIndex, answerIndex }: QuestionAnswerTextProps) {
	const areAnswersBeingChecked = useQuestionGroupStore((state) => state.areAnswersBeingChecked);
	const onAnswerChange = useQuestionGroupStore((state) => state.onAnswerChange);
	const enteredAsnwerTexts = useQuestionGroupStore((state) => state.enteredAsnwerTexts);
	const addEnteredAnswerText = useQuestionGroupStore((state) => state.addEnteredAnswerText);
	const isChangingAnswersDisabled = useQuestionGroupStore((state) => state.isChangingAnswersDisabled);

	const setValue = (value: string) => {
		if (!value || !onAnswerChange) return;

		addEnteredAnswerText({ answerText: value, answerId: answer.id, questionId: answer.question });
	};

	const debouncedSetValue = useDebounceCallback(setValue, 1000);

	if (answer.field_type === FieldType938Enum.MathExpression) {
		const initialLatex = enteredAsnwerTexts.find((text) => text.answerId === answer.id)?.answerText;
		return (
			<QuestionAnswerTextWrapper prefix={answer.display_prefix} suffix={answer.display_sufix}>
				<MathKeyboardInput
					setValue={debouncedSetValue}
					initialValue={initialLatex || ''}
					disabled={isChangingAnswersDisabled}
				/>
			</QuestionAnswerTextWrapper>
		);
	}

	if (answer.field_type === FieldType938Enum.SingleLineText) {
		return (
			<QuestionAnswerTextWrapper prefix={answer.display_prefix} suffix={answer.display_sufix}>
				<QuestionAnswerInput
					answer={answer}
					answerIndex={answerIndex}
					questionIndex={questionIndex}
				/>
			</QuestionAnswerTextWrapper>
		);
	}

	if (answer.field_type === FieldType938Enum.MultiLineText) {
		return (
			<QuestionAnswerTextWrapper prefix={answer.display_prefix} suffix={answer.display_sufix}>
				<QuestionAnswerInput
					answer={answer}
					answerIndex={answerIndex}
					multiline
					questionIndex={questionIndex}
				/>
			</QuestionAnswerTextWrapper>
		);
	}

	return (
		areAnswersBeingChecked && (
			<QuestionAnswer answer={answer} answerIndex={0} questionIndex={questionIndex} />
		)
	);
}

export default QuestionAnswerText;

function QuestionAnswerTextWrapper({
	children,
	prefix,
	suffix,
}: {
	children: React.ReactNode;
	prefix?: string | null;
	suffix?: string | null;
}) {
	return (
		<div className="flex flex-col gap-3">
			<span>{prefix}</span>
			{children}
			<span>{suffix}</span>
		</div>
	);
}
