import { INTERNAL__useCreateOrUpdateAttemptedAnswer } from './client/useCreateOrUpdateAttemptedAnswer';
import { INTERNAL__useCreateQuizAttempt } from './client/useCreateQuizAttempt';
import { INTERNAL__useFinishQuizAttempt } from './client/useFinishQuizAttempt';
import { INTERNAL__useGetActiveQuizAttempt } from './client/useGetActiveQuizAttempt';
import { INTERNAL__useUpdateQuizAttmept } from './client/useUpdateQuizAttmept';

export const INTERNAL__quizzesApiClient = {
	useUpdateQuizAttmept: INTERNAL__useUpdateQuizAttmept,
	useCreateOrUpdateAttemptedAnswer: INTERNAL__useCreateOrUpdateAttemptedAnswer,
	useCreateQuizAttempt: INTERNAL__useCreateQuizAttempt,
	useFinishQuizAttempt: INTERNAL__useFinishQuizAttempt,
	useGetActiveQuizAttempt: INTERNAL__useGetActiveQuizAttempt,
};