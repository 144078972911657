import { Input, theme, Button, Tooltip } from 'antd';
import { useQuestionGroupStore } from './QuestionGroupStore';
import { cn } from '@features/shared/utils/utils';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
} from '@ant-design/icons';
import { Fragment, useRef, useState, type ChangeEvent, type ReactNode } from 'react';
import type { QuestionAnswer } from '@features/api/client';
import stripHtmlTags from '@features/webshop/shared/domain/stripHtmlTags';
import { unique } from 'remeda';
import TextArea from 'antd/es/input/TextArea';
import { KatexProvider, katexTypeset } from '@features/shared/providers/KatexProvider';
import { useDebounceCallback } from 'usehooks-ts';
import EditableText from '../Problem/EditableText';
import EditableProblemRichtext from '@features/frontoffice/problems/ui/Problem/EditableProblemRichtext';

type QuestionAnswerInputProps = {
	answer: QuestionAnswer;
	questionIndex: number;
	answerIndex: number;
	inline?: boolean;
	multiline?: boolean;
};

function QuestionAnswerInputWrapper({ children, inline }: { children: ReactNode; inline?: boolean }) {
	if (inline) return <Fragment>{children}</Fragment>;
	return <div className="flex w-full flex-row items-center gap-2">{children}</div>;
}

function QuestionAnswerInput({
	answer,
	multiline,
	inline,
	questionIndex,
	answerIndex,
}: QuestionAnswerInputProps) {
	const {
		token: { colorSuccess, colorError },
	} = theme.useToken();
	const answersRef = useRef<HTMLSpanElement>(null);

	const isChangingAnswersDisabled = useQuestionGroupStore((state) => state.isChangingAnswersDisabled);
	const addEnteredAnswerText = useQuestionGroupStore((state) => state.addEnteredAnswerText);
	const areAllAnswersBeingShown = useQuestionGroupStore((state) => state.areAllAnswersBeingShown);
	const areAnswersBeingChecked = useQuestionGroupStore((state) => state.areAnswersBeingChecked);
	const isCheckingAnswersInlineEnabled = useQuestionGroupStore(
		(state) => state.isCheckingAnswersInlineEnabled
	);
	const areCorrectAnswersBeingShown = useQuestionGroupStore((state) => state.areCorrectAnswersBeingShown);

	const enteredAsnwerTexts = useQuestionGroupStore((state) => state.enteredAsnwerTexts);
	const setAreAnswersBeingChecked = useQuestionGroupStore((state) => state.setAreAnswersBeingChecked);

	const initialText = enteredAsnwerTexts.find((text) => text.answerId === answer.id)?.answerText || '';

	const [inputValue, setInputValue] = useState(initialText || '');
	const [isCurrentAnswerBeingShown, setIsCurrentAnswerBeingShown] = useState(false);

	const acceptedAnswers = unique([
		answer.display_text,
		...answer.accepted_answers.map((acceptedAnswer) => acceptedAnswer.text),
	]).flat();

	const strippedAcceptedAnswers = acceptedAnswers.map((text) => stripHtmlTags({ text }));

	const acceptedAnswersForDisplay = inline ? strippedAcceptedAnswers : acceptedAnswers;

	const isCorrect = areAnswersBeingChecked
		? strippedAcceptedAnswers.includes(inputValue) ||
			strippedAcceptedAnswers.includes(initialText) ||
			areCorrectAnswersBeingShown
		: false;

	const statusColor = isCorrect ? colorSuccess : colorError;
	const suffixIcon = isCorrect ? (
		<CheckCircleOutlined style={{ color: statusColor }} />
	) : (
		<CloseCircleOutlined style={{ color: statusColor }} />
	);

	const InputElement = multiline ? TextArea : Input;

	const areAnswersVisible = areAllAnswersBeingShown || isCurrentAnswerBeingShown;

	const debouncedAddEnteredAnswerText = useDebounceCallback(addEnteredAnswerText, 1000);

	const onChange = (e: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>) => {
		if (isChangingAnswersDisabled) return;

		setInputValue(e.target.value);
		setAreAnswersBeingChecked(false);
		setIsCurrentAnswerBeingShown(false);

		debouncedAddEnteredAnswerText({
			answerText: e.target.value,
			answerId: answer.id,
			questionId: answer.question,
		});
	};

	let value = inputValue || initialText;

	const shouldShowPlaceholder = areAnswersBeingChecked && !isCorrect && !inputValue;
	if (shouldShowPlaceholder) {
		value = '---------';
	}

	if (areCorrectAnswersBeingShown) {
		value = acceptedAnswers[0] || '---------';
	}

	return (
		<div
			className={cn(
				'relative',
				inline ? 'question-text' : 'flex flex-col gap-2',
				areAnswersBeingChecked && '[&_.ant-input]:!border-inherit'
			)}>
			<QuestionAnswerInputWrapper inline={inline}>
				<InputElement
					readOnly={isChangingAnswersDisabled}
					disabled={isChangingAnswersDisabled}
					rows={multiline ? 6 : undefined}
					value={value}
					defaultValue={initialText}
					status={areAnswersBeingChecked && !isCorrect ? 'error' : undefined}
					style={{
						flex: 1,
						width: 'fit-content',
						minWidth: '184px',
						color: areAnswersBeingChecked ? statusColor : undefined,
						borderColor: areAnswersBeingChecked ? statusColor : undefined,
					}}
					className={cn(inline && '!mx-2 !my-1', areAnswersBeingChecked && !isCorrect && '!mr-0')}
					onChange={onChange}
					suffix={areAnswersBeingChecked ? suffixIcon : null}
				/>
				{isCheckingAnswersInlineEnabled ? (
					<Tooltip title={'Prikaži odgovore'}>
						<Button
							size="small"
							type="link"
							onClick={() => {
								setIsCurrentAnswerBeingShown(!isCurrentAnswerBeingShown);
								katexTypeset();
							}}
							style={{ marginRight: '8px' }}
							className={cn(areAllAnswersBeingShown && 'mb-8')}>
							{areAnswersVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
						</Button>
					</Tooltip>
				) : (
					<span
						className={cn(
							areAllAnswersBeingShown && 'mb-8',
							'mr-2 inline-block h-8 align-top'
						)}></span>
				)}
			</QuestionAnswerInputWrapper>
			{areAnswersVisible && (
				<span
					ref={answersRef}
					className={cn(
						'text-left',
						inline &&
							'absolute left-0 right-0 top-0 translate-y-[32px] px-6 text-sm text-gray-500',
						!inline && 'flex flex-wrap items-center gap-3'
					)}>
					{!inline && <span className="text-sm text-gray-500">Prihvaćeni odgovori: </span>}
					<span className={cn('inline-flex gap-1', !inline && 'flex-col')}>
						<KatexProvider element={answersRef.current}>
							<EditableProblemRichtext
								text={acceptedAnswersForDisplay[0]}
								key={acceptedAnswersForDisplay[0]}
								fieldname={'display_text'}
								modelparams={{ type: 'question_answer', questionIndex, answerIndex }}
							/>
							{acceptedAnswersForDisplay.slice(1).map((acceptedAnswer, index) => (
								<EditableProblemRichtext
									text={acceptedAnswer}
									key={acceptedAnswer}
									fieldname={'text'}
									modelparams={{
										type: 'accepted_answer',
										questionIndex,
										answerIndex,
										acceptAnswerIndex: index,
									}}
								/>
							))}
						</KatexProvider>
					</span>
				</span>
			)}
		</div>
	);
}

export default QuestionAnswerInput;
