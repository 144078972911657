import type { operations } from '@features/api/schema.d';
import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

export function INTERNAL__useGetBankTransactionCategoryAttributionList(
	body: operations['get_bank_account_transaction_category_attribution_list']['parameters']['query']
) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.bankTransactions.transactions._ctx.attributions._ctx.list(body),
	});
}
