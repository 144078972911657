import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { operations } from '@features/api/schema.d';

type DeleteProblemsImportCandidateImageRequest =
	operations['delete_problems_import_candidate_image']['parameters']['path'];
export function INTERNAL__useDeleteProblemsImportCandidateImage({
	problemSourceId,
}: {
	problemSourceId: number;
}) {
	const queryClient = useQueryClient();
	const { queries, apiFetch } = useApiClient();

	const queryKey = queries.problemImports.candidates._ctx.images._ctx.list({
        problemSource: problemSourceId,
    }).queryKey;

	return useMutation({
		mutationFn: async (body: DeleteProblemsImportCandidateImageRequest) => {
			const { data, error } = await apiFetch.DELETE(
				'/wt/backoffice/api/problem-import-candidate-image/{id}/',
				{
					params: {
						path: {
							id: body.id,
						},
					},
				}
			);

			if (error) {
				throw error;
			}

			return data;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey });
		},
	});
}
