import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { schemas } from '@features/api/schema';

export function INTERNAL__useUploadFilesToGoogleDrive() {
	const { queries, apiFetch } = useApiClient();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({ id, body }: { id: number; body: schemas['GoogleDriveUploadFilesRequestRequest'] }) => {
			return apiFetch.POST('/wt/backoffice/api/bank-transaction/{id}/upload-files/', {
				body,
				params: {
					path: {
						id,
					},
				},
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.bankTransactions.transactions.queryKey,
			});
		},
	});
}
