'use client';

import '@styles/text.scss';
import '@styles/animations.scss';

import { ApiClientProvider } from '@features/api/ApiClientContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { onCLS, onINP, onLCP } from 'web-vitals';

import sendToGoogleAnalytics from '../utils/sendToGoogleAnalytics';
import { useEffect } from 'react';
import useCookieConsent from '@features/webshop/shared/domain/useCookieConsent';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import usePreservedDiscountCode from '@features/webshop/cart/hooks/usePreservedDiscountCode';

if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
	posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
		api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
		person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
	});
}

const queryClient = new QueryClient();

type SharedClientProvidersProps = { children: React.ReactNode };

function SharedClientProviders({ children }: SharedClientProvidersProps) {
	usePreservedDiscountCode();

	useEffect(() => {
		if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) return;

		ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);

		onCLS(sendToGoogleAnalytics);
		onINP(sendToGoogleAnalytics);
		onLCP(sendToGoogleAnalytics);
	}, []);

	useCookieConsent();

	return (
		<PostHogProvider client={posthog}>
			<ApiClientProvider>
				<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
			</ApiClientProvider>
		</PostHogProvider>
	);
}

export default SharedClientProviders;
