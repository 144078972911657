'use client';

import type { DiscountCode } from '@features/api/shopify/types';
import { cn } from '@features/shared/utils/utils';
import IconMPartyPopper from '@features/webshop/landing-pages/ui/parts/FluentEmojiIcon/IconMPartyPopper';
import { IconClipboardCheck, IconClipboardCopy } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useCopyToClipboard, useTimeout } from 'usehooks-ts';

function DiscountCodeRibbon({ discountCode, className }: { discountCode: DiscountCode; className?: string }) {
	const [_, copy] = useCopyToClipboard();
	const [isCopied, setIsCopied] = useState(false);
	const [delay, setDelay] = useState<number | null>(null);

	const handleCopy = () => {
		const url = window.location.origin + window.location.pathname;
		const text = `${url}?discount=${discountCode.code}\n\nKod: ${discountCode.code}`;
		copy(text)
			.then(() => {
				setIsCopied(true);
				setDelay(4000);
			})
			.catch((error) => {
				console.error('Failed to copy!', error);
			});
	};

	useTimeout(() => {
		setIsCopied(false);
		setDelay(null);
	}, delay);

	return (
		<div
			className={cn(
				'flex w-full cursor-pointer items-center gap-2 rounded-lg border-2 border-dashed border-white/30 bg-white/20 py-2.5 pl-3.5 pr-5 text-white backdrop-blur-[1px] xs:py-3 sm:w-fit [&>svg]:text-primary-yellow',
				className
			)}
			onClick={handleCopy}>
			<IconMPartyPopper className="h-5 w-5 xs:h-6 xs:w-6" />
			<span className="flex-1 text-sm">
				Kod <span className="max-xs:hidden">za popust</span>
				<span className="ml-1 mr-1.5 inline-flex items-center gap-0.5 text-base font-bold">
					<span className="translate-y-[5%] leading-none">{discountCode.code}</span>
					{isCopied ? (
						<IconClipboardCheck className="h-[18px] w-[18px] opacity-85" />
					) : (
						<IconClipboardCopy className="h-[18px] w-[18px] opacity-85" />
					)}
				</span>
				primijenjen
			</span>
		</div>
	);
}

export default DiscountCodeRibbon;
