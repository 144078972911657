import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

export function INTERNAL__useGetBankAccountData({ initialData }: { initialData?: schemas['BankAccount'] }) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.bankTransactions.balances._ctx.single(),
		initialData,
	});
}
