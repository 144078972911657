/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `expense` - Expense * `income` - Income
 * @export
 * @enum {string}
 */

export const BankAccountTransactionCategoryTypeEnum = {
    Expense: 'expense',
    Income: 'income'
} as const;

export type BankAccountTransactionCategoryTypeEnum = typeof BankAccountTransactionCategoryTypeEnum[keyof typeof BankAccountTransactionCategoryTypeEnum];



