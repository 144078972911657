'use client';

import { apiClient } from '@features/api/api.client';
import type { CustomImage } from '@features/api/client';
import { useProblemEditableStore } from '@features/backoffice/problems-import/ProblemEditableStore';
import type { ModelParams } from '@features/backoffice/problems-import/utils/constructEditedProblemsImportCandidate';
import CloudinaryImage from '@features/shared/ui/CloudinaryImage';
import { IconX } from '@tabler/icons-react';

function ProblemCandidateImage({
	image,
	candidateImageId,
	modelparams,
}: {
	image: CustomImage;
	candidateImageId: number;
	modelparams: ModelParams;
}) {
	const { mutate: updateProblemsImportCandidateImage } =
		apiClient.problemsImport.useUpdateProblemsImportCandidateImage();
	const onEditChange = useProblemEditableStore((state) => state.onEditChange);

	return (
		<div className="relative rounded-md border border-solid border-gray-100 p-1">
			<span
				className="absolute right-2 top-2 flex cursor-pointer items-center justify-center rounded-full bg-primary-red p-1 text-white shadow-sm"
				onClick={() => {
					onEditChange?.({
						modelparams,
						fieldname: 'image_ids',
						newFieldValue: null,
						isEditingComparisonData: false,
						candidateImageId: undefined,
					});

					updateProblemsImportCandidateImage({
						id: candidateImageId,
						patchedProblemsImportCandidateImageRequest: {
							problem_candidate: null,
						},
					});
				}}>
				<IconX className="h-4 w-4" />
			</span>
			<CloudinaryImage
				className="rounded-md"
				src={image.url}
				alt={image.title}
				width={image.width}
				height={image.height}
			/>
		</div>
	);
}

export default ProblemCandidateImage;
