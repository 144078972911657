import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

export function INTENRAL__useGetUniversityList() {
	const { queries } = useApiClient();

	return useQuery({
		...queries.admissionScoreCalculators.universities._ctx.list({}),
	});
}
