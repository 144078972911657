import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';
import type { operations } from '@features/api/schema.d';
export function INTERNAL__useGetCategoryProjectionGroupList(
	body: operations['get_bank_account_transaction_category_projection_group_list']['parameters']['query']
) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.revenue.projectionGroups._ctx.list(body),
	});
}
