import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { schemas } from '@features/api/schema';

export function INTERNAL__useUpdateBankTransactionCategoryAttribution() {
	const queryClient = useQueryClient();
	const { apiFetch, queries } = useApiClient();

	return useMutation({
		mutationFn: ({
			id,
			body,
		}: {
			id: number;
			body: schemas['PatchedBankAccountTransactionCategoryAttributionRequest'];
		}) => {
			return apiFetch.PATCH('/wt/backoffice/api/bank-transaction-category-attribution/{id}/', {
				body,
				params: {
					path: {
						id,
					},
				},
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.bankTransactions.transactions.queryKey,
			});
		},
	});
}
