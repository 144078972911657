/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `FULL_TIME` - Full-time * `PART_TIME` - Part-time
 * @export
 * @enum {string}
 */

export const StatusA79Enum = {
    FullTime: 'FULL_TIME',
    PartTime: 'PART_TIME'
} as const;

export type StatusA79Enum = typeof StatusA79Enum[keyof typeof StatusA79Enum];



