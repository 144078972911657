import useApiClient from '@features/api/useApiClient';
import type { schemas } from '@features/api/schema';
import { useQuery } from '@tanstack/react-query';

export function INTERNAL__useGetBankTransactionVendorList({
	initialData,
}: {
	initialData?: schemas['PaginatedBankAccountTransactionVendorList'];
}) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.bankTransactions.transactions._ctx.vendors._ctx.list({}),
		initialData,
	});
}

