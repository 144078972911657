/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `rich_text` - rich_text * `highlight_box` - highlight_box * `dual_size_image` - dual_size_image * `dual_theme_image` - dual_theme_image * `table` - table
 * @export
 * @enum {string}
 */

export const QuestionBlockSharedTypeEnum = {
    RichText: 'rich_text',
    HighlightBox: 'highlight_box',
    DualSizeImage: 'dual_size_image',
    DualThemeImage: 'dual_theme_image',
    Table: 'table'
} as const;

export type QuestionBlockSharedTypeEnum = typeof QuestionBlockSharedTypeEnum[keyof typeof QuestionBlockSharedTypeEnum];



