import type { schemas } from '@features/api/schema';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function INTERNAL__useCreateBankTransactionSplit() {
	const queryClient = useQueryClient();
	const { apiFetch, queries } = useApiClient();

	return useMutation({
		mutationFn: async (body: schemas['BankAccountTransactionSplitCreateRequest']) => {
			const { data, error } = await apiFetch.POST('/wt/backoffice/api/bank-transaction-split/', {
				body,
			});

			if (error) {
				throw error;
			}

			return data;
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.bankTransactions.transactions.queryKey,
			});
		},
	});
}
