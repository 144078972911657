import { INTERNAL__useCreateBankTransactionVendor } from './client/useCreateBankTransactionVendor';
import { INTERNAL__useGetBankAccountData } from './client/useGetBankAccountData';
import { INTERNAL__useGetBankTransactionList } from './client/useGetBankTransactionList';
import { INTERNAL__useGetBankTransactionVendorList } from './client/useGetBankTransactionVendorList';
import { INTERNAL__useGetUploadedFilesFromGoogleDrive } from './client/useGetUploadedFilesFromGoogleDrive';
import { INTERNAL__useUpdateBankTransaction } from './client/useUpdateBankTransaction';
import { INTERNAL__useUploadFilesToGoogleDrive } from './client/useUploadFilesToGoogleDrive';
import { INTERNAL__useGetBankTransactionCategoryList } from './client/useGetBankTransactionCategoryList';
import { INTERNAL__useCreateBankTransactionCategory } from './client/useCreateBankTransactionCategory';
import { INTERNAL__useCreateBankTransactionSplit } from '@features/backoffice/accounting/transactions/api/client/_useCreateBankTransactionSplit';
import { INTERNAL__useGetBankTransactionCategoryAttributionList } from '@features/backoffice/accounting/transactions/api/client/_useGetBankTransactionCategoryAttributionList';
import { INTERNAL__useUpdateBankTransactionCategoryAttribution } from '@features/backoffice/accounting/transactions/api/client/_useUpdateBankTransactionCategoryAttribution';
import { INTERNAL__useCreateBankTransactionCategoryAttribution } from '@features/backoffice/accounting/transactions/api/client/_useCreateBankTransactionCategoryAttribution';

export const INTERNAL__bankTransactionsApiClient = {
	useCreateBankTransactionVendor: INTERNAL__useCreateBankTransactionVendor,
	useGetBankTransactionVendorList: INTERNAL__useGetBankTransactionVendorList,
	useUpdateBankTransaction: INTERNAL__useUpdateBankTransaction,
	useGetBankTransactionList: INTERNAL__useGetBankTransactionList,
	useGetBankTransactionCategoryAttributionList: INTERNAL__useGetBankTransactionCategoryAttributionList,
	useUpdateBankTransactionCategoryAttribution: INTERNAL__useUpdateBankTransactionCategoryAttribution,
	useCreateBankTransactionCategoryAttribution: INTERNAL__useCreateBankTransactionCategoryAttribution,
	useUploadFilesToGoogleDrive: INTERNAL__useUploadFilesToGoogleDrive,
	useGetUploadedFilesFromGoogleDrive: INTERNAL__useGetUploadedFilesFromGoogleDrive,
	useGetBankAccountData: INTERNAL__useGetBankAccountData,
	useGetBankTransactionCategoryList: INTERNAL__useGetBankTransactionCategoryList,
	useCreateBankTransactionCategory: INTERNAL__useCreateBankTransactionCategory,
	useCreateBankTransactionSplit: INTERNAL__useCreateBankTransactionSplit,
};
