import type { schemas } from '@features/api/schema';
import QuestionAnswerText from '@features/frontoffice/problems/ui/QuestionAnswer/QuestionAnswerText';
import { richtext } from '@features/shared/utils/richtext';
import { chunk } from 'remeda';

type QuestionAnswersTableProps = {
	answers: schemas['QuestionAnswer'][];
	numberOfColumns: number;
	questionIndex: number;
};

function QuestionAnswersTable({ answers, numberOfColumns, questionIndex }: QuestionAnswersTableProps) {
	const answersGrid = chunk(answers, numberOfColumns);

	return (
		<div className="grid border-b border-t border-solid border-gray-100">
			{answersGrid.map((row, index) => (
				<div
					key={index}
					className="grid grid-cols-[repeat(auto-fit,minmax(0,1fr))] border-r border-solid border-gray-100">
					{row.map((answer, answerIndex) => (
						<div
							key={answer.id}
							className="border-b border-l border-solid border-gray-100 px-3 py-5">
							{answer.is_prefilled ? (
								<div>{richtext(answer.display_text)}</div>
							) : (
								<QuestionAnswerText
									key={answer.id}
									answer={answer}
									questionIndex={questionIndex}
									answerIndex={answerIndex}
								/>
							)}
						</div>
					))}
				</div>
			))}
		</div>
	);
}

export default QuestionAnswersTable;
