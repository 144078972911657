'use client';

import Cookies from 'js-cookie';
import type { Session } from 'next-auth';
import { SessionContext, signOut, useSession } from 'next-auth/react';
import { createContext, useLayoutEffect } from 'react';
import {
	AuthApi,
	BackofficeApi,
	Configuration,
	FrontofficeApi,
	SharedApi,
	WebhooksApi,
	WebshopApi,
} from './client';
import { apiClientFetch, type ApiClientFetch } from '@features/api/api.client';
import type { Middleware } from 'openapi-fetch';
import { PROTECTED_PATHS } from '@features/api/protectedPaths';

type ApiClientContextType = {
	authApiClient: AuthApi;
	webshopApiClient: WebshopApi;
	frontofficeApiGen: FrontofficeApi;
	backofficeApiClient: BackofficeApi;
	sharedApiClient: SharedApi;
	webhooksApiClient: WebhooksApi;
	session: Session | null;
	apiFetch: ApiClientFetch;
};

const ApiClientContext = createContext<Partial<ApiClientContextType>>({
	authApiClient: undefined,
	webshopApiClient: undefined,
	frontofficeApiGen: undefined,
	backofficeApiClient: undefined,
	sharedApiClient: undefined,
	webhooksApiClient: undefined,
	session: null,
	apiFetch: undefined,
});

function apiClientMiddleware(headers?: Headers): Middleware {
	return {
		async onRequest({ request, schemaPath }) {
			if (!PROTECTED_PATHS.some((path) => schemaPath?.includes(path))) return request;

			const authorizationHeader = headers?.get('Authorization');
			if (authorizationHeader) {
				request.headers.set('Authorization', authorizationHeader);
			}

			const csrfTokenHeader = headers?.get('X-CSRFToken');
			if (csrfTokenHeader) {
				request.headers.set('X-CSRFToken', csrfTokenHeader);
			}

			return request;
		},
	};
}

const ApiClientProvider = ({ children }: any) => {
	const { data: session, status } = useSession();
	const csrfToken = Cookies.get('csrftoken') || Cookies.get('next-auth.csrf-token');
	const headers = new Headers();

	let headersObject: Record<string, string> = {};

	if (session?.access) {
		headers.set('Authorization', `Bearer ${session?.access}`);
		headersObject['Authorization'] = `Bearer ${session?.access}`;
	}

	if (csrfToken) {
		headers.set('X-CSRFToken', csrfToken);
		headersObject['X-CSRFToken'] = csrfToken;
	}

	const configuration = new Configuration({
		basePath: process.env.NEXT_PUBLIC_API_BASE_PATH,
		baseOptions: {
			headers: headersObject,
		},
	});

	const authApiClient = new AuthApi(configuration);
	const webshopApiClient = new WebshopApi(configuration);
	const frontofficeApiGen = new FrontofficeApi(configuration);
	const backofficeApiClient = new BackofficeApi(configuration);
	const sharedApiClient = new SharedApi(configuration);
	const webhooksApiClient = new WebhooksApi(configuration);

	useLayoutEffect(() => {
		if (session?.error === 'REFRESH_TOKEN_ERROR') {
			signOut();
		}

		if (status === 'authenticated') {
			apiClientFetch.use(apiClientMiddleware(headers));
		}
	}, [session]);

	if (status === 'loading') return null;

	return (
		<ApiClientContext.Provider
			value={{
				authApiClient,
				webshopApiClient,
				frontofficeApiGen,
				backofficeApiClient,
				sharedApiClient,
				webhooksApiClient,
				session,
				apiFetch: apiClientFetch,
			}}>
			{children}
		</ApiClientContext.Provider>
	);
};

export { ApiClientContext, ApiClientProvider, SessionContext };
