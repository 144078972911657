import type { ApiClientFetch } from '@features/api/api.client';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const INTERNAL__coursesQueryConfig = (apiFetch: ApiClientFetch) =>
	createQueryKeys('courses', {
		course: (courseId: number) => ({
			queryKey: ['single', courseId],
			contextQueries: {
				courseCategory: {
					queryKey: ['course-category'],
					queryFn: async () => {
						const { data, error } = await apiFetch.GET(
							'/wt/shared/api/course/{id}/course-category/',
							{
								params: {
									path: {
										id: courseId,
									},
								},
							}
						);

						if (error) {
							throw error;
						}

						return data;
					},
				},
			},
		}),
	});
